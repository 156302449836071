import Link from '@/components/link'

import { oss_svg_image_domain_address } from '@/constants/oss'

import LazyImage, { Type } from '@/components/lazy-image'
import styles from './index.module.css'

export function Logo() {
  return (
    <Link href="/" className={styles.scoped}>
      <LazyImage imageType={Type.png} src={`${oss_svg_image_domain_address}logo`} alt={'Vanshi Tech Logo'} />
    </Link>
  )
}
