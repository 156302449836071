import { baseLayoutStore } from '@/store/layout'
import { TlayoutProps } from '@/typings/api/layout'
import {
  YapiGetV1HomeWebsiteGetData,
  YapiGetV1HomeWebsiteGetDataApiRequest,
} from '@/typings/yapi/HomeWebsiteGetDataV1GetApi'
import { omit } from 'lodash'
import { t } from '@lingui/macro'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { businessId } from '../env'
import { extractFooterData } from './footer'

async function getLayoutProps(
  lanType: YapiGetV1HomeWebsiteGetDataApiRequest['lanType']
): Promise<YapiGetV1HomeWebsiteGetData | TlayoutProps | undefined> {
  return {
    keywords: t`helper_layout_index_kblmy4qlki`,
    shareCoverUrl: `${oss_svg_image_domain_address}social_media_share.png`,
  } as any
}

function initializeLayoutStore(pageContext) {
  const layoutStore = baseLayoutStore.getState()
  const { setHeaderData, setLayoutProps, setWebFooterData, setH5FooterData } = layoutStore
  const { layoutProps } = pageContext
  setLayoutProps(layoutProps)
  setHeaderData(omit(layoutProps, ['list']))
  // const headerData = extractHeaderData(layoutProps)
  const { webFooter, h5Footer } = extractFooterData(layoutProps) || {}
  h5Footer && setH5FooterData(h5Footer)
  webFooter && setWebFooterData(webFooter)
  // setColumnsDataByCd(recursiveColumnMap(layoutProps?.columnsDatas || []))
  // setHeaderData(headerData)
  // setFooterData(footerData)
}
export { getLayoutProps, initializeLayoutStore }
