import { addGlobalLibrary } from '@/helper/externals'
import { logGitCommitId } from '../common'
import { initSentry } from './utils/sentry'
import { getDeviceId } from './utils/client-device-id'
import { initCache } from '../cache/common'
import { initClientApi } from './utils/init-api'
import { initVConsoleOnDev } from '../log'
import { dynamicActivate } from '../i18n'
import { handleRouterLocale } from './utils/init-locale'

/**
 * 初始化 客户端能力，例如注册 ws
 */
export const onInstallForClient = async (pageContext: PageContext) => {
  const locale = pageContext.locale
  initSentry()
  getDeviceId()
  await dynamicActivate(locale!)
  /** 探测持久化储存 */
  initCache()
  /** 添加全局库 */
  addGlobalLibrary()
  // dev vConsole
  initVConsoleOnDev()
  /** 注册 api */
  initClientApi()
  /** 额外功能 */
  logGitCommitId()
  /** 处理路由传入的多语言 */
  handleRouterLocale(pageContext)
}
