import Icon from '@/components/icon'
import { Trigger } from '@arco-design/web-react'
import { Collapse } from '@nbit/arco'
import { useState } from 'react'
import Link from '@/components/link'
import { map } from 'lodash'
import { useLockBodyScroll } from 'react-use'
import { useScroll } from 'ahooks'
import classNames from 'classnames'
import styles from './header.module.css'
import { I18nSelect } from './components/personalization'
import { HeaderMenu } from './components/header-menu'
import { getHeaderMenus } from './header-menus-url'
import { Logo } from './components/logo'

const CollapseItem = Collapse.Item

function Header() {
  const [activeKey, setActiveKey] = useState<string>('')

  // icon_drop
  const dynamicExpandIcon = (key: string) => {
    if (activeKey && activeKey === key) {
      return <Icon fontSize={8} name="regsiter_icon_up_pre" className="rotate-90" />
    }
    return <Icon fontSize={8} name="regsiter_icon_drop_pre" />
  }
  const [visible, setVisible] = useState(false)

  useLockBodyScroll(visible)
  const position = useScroll()

  return (
    <div className={classNames(styles.scoped, { 'slide-down': (position?.top ?? 0) > 50 }, { 'bg-color': visible })}>
      <div className="header-inner">
        <Logo />
        <div className={styles.menus}>
          <HeaderMenu />
          <I18nSelect trigger="hover" position="bottom" popupAlign={{ bottom: 30 }} />
        </div>
        <div className="flex items-center lg:hidden">
          <I18nSelect position="br" popupAlign={{ bottom: 14 }} />
          <Trigger
            trigger={'click'}
            popupAlign={{ bottom: 14 }}
            position="bottom"
            clickToClose
            popupVisible={visible}
            style={{ zIndex: 20 }}
            onVisibleChange={setVisible}
            popup={() => {
              return (
                <div className={styles['trigger-popup']}>
                  {getHeaderMenus().map((menu, i) =>
                    menu.submenu === null ? (
                      <div key={i} onClick={() => setVisible(false)} className="link-item">
                        <Link href={menu.url}>{menu.name}</Link>
                      </div>
                    ) : (
                      <Collapse
                        key={i}
                        accordion
                        expandIconPosition="right"
                        activeKey={activeKey}
                        onChange={key => setActiveKey(activeKey !== key ? key : '')}
                      >
                        <CollapseItem
                          key={menu.name}
                          header={menu.name}
                          name={String(i)}
                          expandIcon={dynamicExpandIcon(String(i))}
                        >
                          {map(menu.submenu, (child, i) => (
                            <div className="link-item-box" key={i} onClick={() => setVisible(false)}>
                              <Link href={child.url}>
                                <div
                                  onClick={() => {
                                    setActiveKey('')
                                  }}
                                  className="menu-item"
                                >
                                  {child.iconName ? <Icon fontSize={32} name={child.iconName} /> : null}
                                  <div className="title">{child.name}</div>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </CollapseItem>
                      </Collapse>
                    )
                  )}
                </div>
              )
            }}
          >
            <div className="ml-4">
              <Icon hiddenMarginTop fontSize={16} name="h5_toolbar_icon" />
            </div>
          </Trigger>
        </div>
      </div>
    </div>
  )
}

export default Header
