import { setLocale } from '@/helper/i18n'
import { I18nsEnum } from '@/constants/i18n'
import { baseCommonStore } from '@/store/common'

export function handleRouterLocale(pageContext: PageContext) {
  const routerLocale = pageContext.urlParsed?.search?.locale as I18nsEnum
  if (!routerLocale) return
  const { locale } = baseCommonStore.getState()
  if (routerLocale === locale) {
    return
  }
  setLocale(routerLocale)
}
