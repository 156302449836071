import create from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import { TlayoutProps } from '@/typings/api/layout'
import { extractFooterData } from '@/helper/layout/footer'
import { devtools } from 'zustand/middleware'
import { YapiGetV1ChainStarBottomBarData } from '@/typings/yapi/ChainStarBottomBarV1GetApi'
import { YapiGetV1ChainStarNewsListData } from '@/typings/yapi/ChainStarNewsV1GetApi'
import { YapiGetV1ChainStarHomeGroupConfigListConfigListData } from '@/typings/yapi/ChainStarHomeGroupConfigV1GetApi'
import { YapiGetV1ChainStarGetNavigationListData } from '@/typings/yapi/ChainStarGetNavigationV1GetApi'

type TLayoutStore = ReturnType<typeof getStore>

function getStore(set) {
  return {
    layoutProps: {} as TlayoutProps | undefined,
    setLayoutProps: (layoutProps?: TlayoutProps | undefined) =>
      set(() => {
        if (layoutProps) {
          return { layoutProps }
        }
        return {}
      }),
    navigationMenu: [] as YapiGetV1ChainStarGetNavigationListData[],
    setNavigationMenu: (data: YapiGetV1ChainStarGetNavigationListData[]) =>
      set(
        produce((draft: TLayoutStore) => {
          draft.navigationMenu = data
        })
      ),
    footerData1: {} as YapiGetV1ChainStarBottomBarData,
    homeGroupData: [] as YapiGetV1ChainStarHomeGroupConfigListConfigListData[],
    chainStarNewsStart: [] as YapiGetV1ChainStarNewsListData[],
    chainStarNewsEnd: [] as YapiGetV1ChainStarNewsListData[],
    webFooterData: [] as ReturnType<typeof extractFooterData>['webFooter'],
    h5FooterData: [] as ReturnType<typeof extractFooterData>['h5Footer'],
    setWebFooterData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.webFooterData = data
        })
      ),
    setH5FooterData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.h5FooterData = data
        })
      ),
    // basic web data
    headerData: { businessName: '' } as YapiGetV1ChainStarBottomBarData,
    setHeaderData: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.headerData = data
        })
      ),
    fetchChainStarNews: async params => {},
    fetchChainStarBottomBar: async params => {},
    fetchChainStarHomeGroupConfig: async params => {},
    columnsDataByCd: {} as Record<string, any>,
    setColumnsDataByCd: data =>
      set(
        produce((draft: TLayoutStore) => {
          draft.columnsDataByCd = data
        })
      ),
  }
}
const baseLayoutStore = create(devtools(getStore, { name: 'layout-store' }))

const useLayoutStore = createTrackedSelector(baseLayoutStore)

export { useLayoutStore, baseLayoutStore }
