import Link from '@/components/link'
import { t } from '@lingui/macro'
import { ROUTE_PATH } from '@/constants/route'
import Icon from '@/components/icon'
import classNames from 'classnames'
import { useCommonStore } from '@/store/common'
import styles from './index.module.css'
import { getServicesMenus } from '../../header-menus-url'

type FooterMenuListType = {
  name: string
  url?: string
  iconName?: string
  isEmail?: boolean
}

type FooterMenuType = {
  title: string
  list: FooterMenuListType[]
}

// footer data array
function getFooterMenus() {
  const serviceMenus = getServicesMenus()
  return [
    {
      title: t`features_layout_header_menus_url__45xlkluau`,
      url: ROUTE_PATH.SERVICES,
      list: serviceMenus.map(i => ({ ...i, iconName: '' })),
    },
    {
      title: t`features_layout_header_menus_url_utcwj1apnn`,
      list: [
        { name: t`features_vanshi_about_us_index_oxanvhdo2k`, url: `${ROUTE_PATH.ABOUT_US}#introduction` },
        { name: t`features_layout_footer_footer_menu_index_jbbuxzwzny`, url: `${ROUTE_PATH.ABOUT_US}#commitment` },
        { name: t`features_vanshi_about_us_index_bwb0bhhsda`, url: `${ROUTE_PATH.ABOUT_US}#roadmap` },
      ],
      url: ROUTE_PATH.ABOUT_US,
    },
    {
      title: t`features_layout_header_menus_url_y4xj2snij9`,
      list: [
        { name: '+65 8795 2222', iconName: 'bottom_phone-call', url: ROUTE_PATH.CONTACT_US },
        { name: 'business@vanshi.com', isEmail: true, iconName: 'bottom_mail', url: ROUTE_PATH.CONTACT_US },
        {
          name: '78A&80A Amoy Street, Singapore, 069899',
          iconName: 'bottom_address',
          url: ROUTE_PATH.CONTACT_US,
        },
      ],
      url: ROUTE_PATH.CONTACT_US,
    },
  ]
}

function FooterMenuList({ list }: { list: FooterMenuListType[] }) {
  return (
    <>
      {list.map((each, idx) => {
        return (
          <span key={idx} className="menu-list">
            <Link
              href={each?.url || ''}
              className={classNames('footer-link', { 'footer-text-with-icon': each?.iconName })}
            >
              {each?.iconName ? <Icon name={each.iconName} /> : null}
              {each.name}
            </Link>
          </span>
        )
      })}
    </>
  )
}

function FooterMenu() {
  const { locale } = useCommonStore()
  return (
    <div className={styles.scoped}>
      <div className="menus-wrapper">
        {getFooterMenus().map(footer => (
          <div key={footer.title} className={classNames('footer-menu-item', { en: locale === 'en-US' })}>
            <div className="footer-title">
              <Link href={footer.url}>{footer.title}</Link>
            </div>
            <FooterMenuList list={footer.list} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FooterMenu
