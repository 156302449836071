import { ROUTE_PATH } from '@/constants/route'
import { t } from '@lingui/macro'
import { ServiceListID } from '../home/services'

export const getServicesMenus = () => [
  {
    name: t`features_layout_header_menus_url_sctzkci_5x`,
    iconName: 'icon_web3_pre',
    url: `${ROUTE_PATH.SERVICES}#${ServiceListID.social}`,
  },
  {
    name: t`features_home_services_3cvnrndhjj`,
    iconName: 'icon_vpn_pre',
    url: `${ROUTE_PATH.SERVICES}#${ServiceListID.vpn}`,
  },
  {
    name: t`features_layout_header_menus_url_gjj2xjiojx`,
    iconName: 'icon_blockchain_pre',
    url: `${ROUTE_PATH.SERVICES}#${ServiceListID.proxy}`,
  },
  {
    name: t`features_home_services_7oltmd3oq2`,
    iconName: 'icon_system_pre',
    url: `${ROUTE_PATH.SERVICES}#${ServiceListID.dev}`,
  },
]
export const getHeaderMenus = () => {
  const serviceMenus = getServicesMenus()
  return [
    {
      url: ROUTE_PATH.SERVICES,
      name: t`features_layout_header_menus_url__45xlkluau`,
      submenu: serviceMenus,
    },
    {
      url: ROUTE_PATH.ABOUT_US,
      name: t`features_layout_header_menus_url_utcwj1apnn`,
      submenu: null,
    },
    {
      url: ROUTE_PATH.CONTACT_US,
      name: t`features_layout_header_menus_url_y4xj2snij9`,
      submenu: null,
    },
  ]
}
