import { TopButton } from '@/components/top-button'
import styles from './index.module.css'

function SideBar() {
  return (
    <div className={styles.scoped}>
      <TopButton smooth top={500} />
    </div>
  )
}

export default SideBar
