import { t } from '@lingui/macro'
import styles from './index.module.css'
import FooterMenu from './footer-menu'
import FooterIcons from './footer-icons'
import { Logo } from '../components/logo'

function Footer() {
  return (
    <div className={styles.container}>
      <div className="footer-inner">
        <div className="footer-wrap">
          <div className="footer-logo">
            <Logo />
            <div className="footer-text">{t`features_layout_footer_index_ve2phmmdli`}</div>
            {/* <div className="hidden lg:block">
              <FooterIcons />
            </div> */}
          </div>
          <FooterMenu />
        </div>
        {/* <div className="block lg:hidden">
          <FooterIcons />
        </div> */}
        <div className="footer-bootom">
          <div>2023 Vanshi Tech Technology</div>
          <div className="mt-2 lg:mt-0 lg:ml-8">{t`features_layout_footer_index_zbz7pupeae`}</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
