import React from 'react'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { Layout as ArcoLayout, ConfigProvider } from '@nbit/arco'
import zhCN from '@nbit/arco/lib/locale/zh-CN'
import zhHK from '@nbit/arco/lib/locale/zh-HK'
import enUS from '@nbit/arco/lib/locale/en-US'
import Header from '@/features/layout/header'
import { I18nsEnum } from '@/constants/i18n'
import { PageContextProvider } from '@/hooks/use-page-context'
import ErrorBoundary from '@/components/error-boundary'
import Footer from '@/features/layout/footer'
import type { ComponentConfig } from '@nbit/arco/es/ConfigProvider/interface'
import LoadingElement from '@/components/loading-element'
import ListEmpty from '@/components/list-empty'
import SideBar from '@/features/layout/side-bar'

import 'animate.css'
import { oss_svg_image_domain_address } from '@/constants/oss'
import LazyImage, { Type } from '@/components/lazy-image'

export default Layout

const ArcoLayoutHeader = ArcoLayout.Header
const ArcoLayoutFooter = ArcoLayout.Footer
const ArcoLayoutContent = ArcoLayout.Content

const arcoComponentConfig: ComponentConfig = {
  Spin: {
    element: <LoadingElement />,
  },
  Table: {
    noDataElement: <ListEmpty />,
  },
}

function Layout({ pageContext, children }: { pageContext: PageContext; children: React.ReactNode }) {
  const isFullScreen = pageContext.layoutParams?.fullScreen
  const headerShow = pageContext.layoutParams?.headerShow
  const footerShow = pageContext.layoutParams?.footerShow

  const locale = pageContext.locale

  function getLocale(localeVal?: string) {
    switch (localeVal) {
      case I18nsEnum['zh-CN']:
        return zhCN

      case I18nsEnum['en-US']:
        return enUS

      case I18nsEnum['zh-HK']:
        return zhHK

      default:
        return zhCN
    }
  }

  return (
    <PageContextProvider pageContext={pageContext}>
      <I18nProvider i18n={i18n}>
        <ConfigProvider componentConfig={arcoComponentConfig} locale={getLocale(locale)}>
          {/* className={isFullScreen ? 'layout-fullscreen-wrap' : 'layout-wrap'} */}
          <div id="layout" className="layout-wrap">
            <ErrorBoundary>
              <ArcoLayoutContent>
                <div className="layout-content">
                  {headerShow ? <Header /> : null}
                  {/* <LazyImage
                    imageType={Type.png}
                    className="home-bg-01"
                    src={`${oss_svg_image_domain_address}home_bg_01`}
                  />
                  <LazyImage
                    imageType={Type.png}
                    className="home-bg-02"
                    src={`${oss_svg_image_domain_address}home_bg_02`}
                  /> */}
                  <div className="layout-content-inner">{children}</div>
                </div>
              </ArcoLayoutContent>
              <ArcoLayoutContent>
                <SideBar />
              </ArcoLayoutContent>
              {footerShow ? (
                <ArcoLayoutFooter>
                  <Footer />
                </ArcoLayoutFooter>
              ) : null}
            </ErrorBoundary>
          </div>
        </ConfigProvider>
      </I18nProvider>
    </PageContextProvider>
  )
}
