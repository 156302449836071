import cn from 'classnames'
import { Trigger } from '@arco-design/web-react'

import Icon from '@/components/icon'
import Link from '@/components/link'

import { usePageContext } from '@/hooks/use-page-context'

import { map } from 'lodash'
import { useState } from 'react'
import { useLockBodyScroll } from 'react-use'
import styles from './header-menu.module.css'
import { getHeaderMenus } from '../header-menus-url'
import popupStyles from './personalization.module.css'

export function HeaderMenu() {
  const [visible, setVisible] = useState(false)
  const pageContext = usePageContext()

  const path = pageContext.path
  useLockBodyScroll(visible)
  return (
    <div className={styles.scoped}>
      {map(getHeaderMenus(), (route, i) => {
        if (route.submenu === null) {
          return (
            <div key={route?.name} className={cn('menu-item-wrap', { selected: route?.url === path })}>
              <Link className="menu-text" href={route?.url}>
                {route?.name}
              </Link>
            </div>
          )
        }
        return (
          <Trigger
            key={route?.name}
            position="bottom"
            popupAlign={{ bottom: 30 }}
            style={{ zIndex: 20 }}
            popupVisible={visible}
            onVisibleChange={setVisible}
            popup={() => {
              return (
                <div className={popupStyles['trigger-popup']}>
                  <div className="container">
                    {map(route.submenu, (item, index) => {
                      return (
                        <Link key={index} href={item.url}>
                          <div className="item">
                            {item.iconName ? <Icon name={item.iconName} className="mr-2" fontSize={24} /> : null}
                            {item?.name}
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            }}
          >
            <div className={cn('menu-item-wrap', { selected: route?.url === path })}>
              <Link href={route?.url}>
                <div className="menu-text">{route?.name}</div>
                <Icon
                  className="ml-2 mt-0.5"
                  fontSize={8}
                  name={visible ? 'regsiter_icon_up_pre' : 'regsiter_icon_drop_pre'}
                />
              </Link>
            </div>
          </Trigger>
        )
      })}
    </div>
  )
}
