import ScrollToTop from 'react-scroll-to-top'

import styles from './index.module.css'
import Icon from '../icon'

export interface TopButtonProps {
  // // 是否始终显示
  // alwaysShow?: boolean
  top?: number
  smooth?: boolean
  svgPath?: string
  viewBox?: string
  component?: any
  width?: string
  height?: string
}

export function TopButton(props: TopButtonProps) {
  return (
    <div className={styles['top-button']}>
      <ScrollToTop {...props} component={<Icon name="top_nor" hoverName="top_pre" />} />
    </div>
  )
}
